<template>
  <div class="mt-1">    
    <div class="d-flex w-100 mb-2">
      <b-input-group class="input-group-merge">
        <b-form-input
          v-model="searchUsername"
          :placeholder="$i18n.t('Insira o nome do usuário que procura')"
          :state="userNotFount"
        />
      </b-input-group>
      <b-button size="sm" variant="outline-primary" class="mx-1" @click="top">
        {{ $t("Topo") }}
      </b-button>
      <b-button size="sm" variant="outline-primary" @click="up">
        {{ $t("Acima") }}
      </b-button>
    </div>
    <div
      class="
        d-flex
        flex-column flex-lg-row
        justify-content-between
        align-items-center
        mb-2
      "
    >
      <div>
        <h5>
          {{ $t("Minha perna esquerda hoje") }}:
          {{
            parseInt(ownerBinary.pointsChildLeft
              ? ownerBinary.pointsChildLeft -
                (ownerBinary.pointsEarned ? ownerBinary.pointsEarned : 0) -
                (ownerBinary.pointsLost ? ownerBinary.pointsLost : 0)
              : 0)
          }}
          pts
        </h5>
        <h5>
          {{ $t("Minha perna direita hoje") }}:
          {{
            parseInt(ownerBinary.pointsChildRight
              ? ownerBinary.pointsChildRight -
                (ownerBinary.pointsEarned ? ownerBinary.pointsEarned : 0) -
                (ownerBinary.pointsLost ? ownerBinary.pointsLost : 0)
              : 0)
          }}
          pts
        </h5>
        <div>
          <span class="text-danger" v-if="!ownerBinary.binaryActiveLeft || !ownerBinary.binaryActiveRight">{{ $t("Bônus binário inativo") }}</span>
          <span class="text-success" v-if="ownerBinary.binaryActiveLeft && ownerBinary.binaryActiveRight">{{ $t("Bônus binário ativo") }}</span>
        </div>
      </div>
      <div class="my-1 my-lg-0">
        <div class="d-flex mb-1">
      <b-form-input
        v-model="referenceLink"
        class="d-inline-block mr-1"
        disabled
        style="height: 2.1rem"
      />
      <b-button
        variant="primary"
        class="w-25 d-none d-lg-flex justify-content-center"
        @click="copyReferenceLink"
        size="sm"
      >
        {{ $t("Copiar link") }}
      </b-button>
      <b-button
        variant="primary"
        class="w-25 d-flex d-lg-none justify-content-center"
        @click="copyReferenceLink"
        size="sm"
      >
        {{ $t("Copiar") }}
      </b-button>
    </div>
        <div class="d-flex justify-content-between align-items-center">
          <b-form-radio
            v-model="selectedLeg"
            name="some-radios"
            value="left"
            class="mr-1"
          >
            {{ $t("Esquerda") }}
          </b-form-radio>
          <b-form-radio
            v-model="selectedLeg"
            name="some-radios"
            value="right"
            class="mr-1"
          >
            {{ $t("Direita") }}
          </b-form-radio>
          <b-form-radio
            v-model="selectedLeg"
            name="some-radios"
            value="auto"
            class="mr-1"
          >
            <span class="d-none d-lg-block">{{
              $t("Perna com menos pontos")
            }}</span>
            <span class="d-block d-lg-none">{{ $t("Menor") }}</span>
          </b-form-radio>
          <b-button size="sm" variant="primary" @click="saveSelectedLeg">
            {{ $t("Salvar") }}
          </b-button>
        </div>
        <alert
          :text="textAlert"
          :color="colorAlert"
          :showAlert="showAlert"
          @changeVariableAlert="(val) => (showAlert = val)"
          style="position: absolute; width: 28rem; margin-top: 0.5rem"
        />
      </div>
    </div>
    <div class="text-center mt-5 mt-lg-0">
      <b-col cols="12">
        <div
          @click="
            () =>
              selectUser(
                diagramBinaryTree[0] && diagramBinaryTree[0].idUser.username
              )
          "
          class="cursor-pointer"
        >
          <b-popover
            target="popover-target-0"
            triggers="hover"
            :delay="delay"
            placement="top"
            v-if="diagramBinaryTree[0]"
          >
            <template #title>
              {{ diagramBinaryTree[0] && diagramBinaryTree[0].idUser.fullname }}
            </template>
            <div>
              <div>
                {{ $t("Status") }}:
                {{
                  diagramBinaryTree[0].pointsUser > 0
                    ? $t("ativo")
                    : $t("inativo")
                }}
              </div>
              <div>
                {{ $t("Pontuação gerada") }}:
                {{
                  parseInt(diagramBinaryTree[0].pointsUser
                    ? diagramBinaryTree[0].pointsUser
                    : 0)
                }}
              </div>
              <div>
                {{ $t("Qtd. de ativos na esquerda") }}:
                {{
                  parseInt(diagramBinaryTree[0].lenghtBinaryLeft
                    ? diagramBinaryTree[0].lenghtBinaryLeft
                    : 0)
                }}
              </div>
              <div>
                {{ $t("Qtd. de ativos na direita") }}:
                {{
                  parseInt(diagramBinaryTree[0].lenghtBinaryRight
                    ? diagramBinaryTree[0].lenghtBinaryRight
                    : 0)
                }}
              </div>
              <div>
                {{ $t("Pontos perna esquerda") }}:
                {{
                  parseInt(diagramBinaryTree[0].pointsChildLeft
                    ? diagramBinaryTree[0].pointsChildLeft
                    : 0)
                }}
              </div>
              <div>
                {{ $t("Pontos perna direita") }}:
                {{
                  parseInt(diagramBinaryTree[0].pointsChildRight
                    ? diagramBinaryTree[0].pointsChildRight
                    : 0)
                }}
              </div>
            </div>
          </b-popover>
          <b-avatar
            size="70px"
            :variant="
              diagramBinaryTree[0]
                ? diagramBinaryTree[0].idUser.status == 'active'
                  ? 'light-success'
                  : 'light-info'
                : 'light-danger'
            "
            text="PA"
            id="popover-target-0"
          />
          <div class="mt-1">
            <div>
              <strong>{{
                diagramBinaryTree[0]
                  ? diagramBinaryTree[0].idUser.username
                  : $t("livre")
              }}</strong>
            </div>
          </div>
        </div>
      </b-col>
      <b-row class="mt-2">
        <b-col cols="6">
          <div
            @click="
              () =>
                selectUser(
                  diagramBinaryTree[1] && diagramBinaryTree[1].idUser.username
                )
            "
            class="cursor-pointer"
          >
            <b-popover
              target="popover-target-1"
              triggers="hover"
              :delay="delay"
              placement="top"
              v-if="diagramBinaryTree[1]"
            >
              <template #title>
                {{
                  diagramBinaryTree[1] && diagramBinaryTree[1].idUser.fullname
                }}
              </template>
              <div>
                <div>
                  {{ $t("Status") }}:
                  {{
                    diagramBinaryTree[1].pointsUser > 0
                      ? $t("ativo")
                      : $t("inativo")
                  }}
                </div>
                <div>
                  {{ $t("Pontuação gerada") }}:
                  {{
                    diagramBinaryTree[1].pointsUser
                      ? diagramBinaryTree[1].pointsUser
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Qtd. de ativos na esquerda") }}:
                  {{
                    diagramBinaryTree[1].lenghtBinaryLeft
                      ? diagramBinaryTree[1].lenghtBinaryLeft
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Qtd. de ativos na direita") }}:
                  {{
                    diagramBinaryTree[1].lenghtBinaryRight
                      ? diagramBinaryTree[1].lenghtBinaryRight
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Pontos perna esquerda") }}:
                  {{
                    diagramBinaryTree[1].pointsChildLeft
                      ? diagramBinaryTree[1].pointsChildLeft
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Pontos perna direita") }}:
                  {{
                    diagramBinaryTree[1].pointsChildRight
                      ? diagramBinaryTree[1].pointsChildRight
                      : 0
                  }}
                </div>
              </div>
            </b-popover>
            <b-avatar
              size="70px"
              :variant="
                diagramBinaryTree[1]
                  ? diagramBinaryTree[1].idUser.status == 'active'
                    ? 'light-success'
                    : 'light-info'
                  : 'light-danger'
              "
              text="PA"
              id="popover-target-1"
            />
            <div class="mt-1">
              <div>
                <strong>{{
                  diagramBinaryTree[1]
                    ? diagramBinaryTree[1].idUser.username
                    : $t("livre")
                }}</strong>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="6">
          <div
            @click="
              () =>
                selectUser(
                  diagramBinaryTree[2] && diagramBinaryTree[2].idUser.username
                )
            "
            class="cursor-pointer"
          >
            <b-popover
              target="popover-target-2"
              triggers="hover"
              :delay="delay"
              placement="top"
              v-if="diagramBinaryTree[2]"
            >
              <template #title>
                {{
                  diagramBinaryTree[2] && diagramBinaryTree[2].idUser.fullname
                }}
              </template>
              <div>
                <div>
                  {{ $t("Status") }}:
                  {{
                    diagramBinaryTree[2].pointsUser > 0
                      ? $t("ativo")
                      : $t("inativo")
                  }}
                </div>
                <div>
                  {{ $t("Pontuação gerada") }}:
                  {{
                    diagramBinaryTree[2].pointsUser
                      ? diagramBinaryTree[2].pointsUser
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Qtd. de ativos na esquerda") }}:
                  {{
                    diagramBinaryTree[2].lenghtBinaryLeft
                      ? diagramBinaryTree[2].lenghtBinaryLeft
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Qtd. de ativos na direita") }}:
                  {{
                    diagramBinaryTree[2].lenghtBinaryRight
                      ? diagramBinaryTree[2].lenghtBinaryRight
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Pontos perna esquerda") }}:
                  {{
                    diagramBinaryTree[2].pointsChildLeft
                      ? diagramBinaryTree[2].pointsChildLeft
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Pontos perna direita") }}:
                  {{
                    diagramBinaryTree[2].pointsChildRight
                      ? diagramBinaryTree[2].pointsChildRight
                      : 0
                  }}
                </div>
              </div>
            </b-popover>
            <b-avatar
              size="70px"
              :variant="
                diagramBinaryTree[2]
                  ? diagramBinaryTree[2].idUser.status == 'active'
                    ? 'light-success'
                    : 'light-info'
                  : 'light-danger'
              "
              text="PA"
              id="popover-target-2"
            />
            <div class="mt-1">
              <div>
                <strong>{{
                  diagramBinaryTree[2]
                    ? diagramBinaryTree[2].idUser.username
                    : $t("livre")
                }}</strong>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="3">
          <div
            @click="
              () =>
                selectUser(
                  diagramBinaryTree[3] && diagramBinaryTree[3].idUser.username
                )
            "
            class="cursor-pointer"
          >
            <b-popover
              target="popover-target-3"
              triggers="hover"
              :delay="delay"
              placement="top"
              v-if="diagramBinaryTree[3]"
            >
              <template #title>
                {{
                  diagramBinaryTree[3] && diagramBinaryTree[3].idUser.fullname
                }}
              </template>
              <div>
                <div>
                  {{ $t("Status") }}:
                  {{
                    diagramBinaryTree[3].pointsUser > 0
                      ? $t("ativo")
                      : $t("inativo")
                  }}
                </div>
                <div>
                  {{ $t("Pontuação gerada") }}:
                  {{
                    diagramBinaryTree[3].pointsUser
                      ? diagramBinaryTree[3].pointsUser
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Qtd. de ativos na esquerda") }}:
                  {{
                    diagramBinaryTree[3].lenghtBinaryLeft
                      ? diagramBinaryTree[3].lenghtBinaryLeft
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Qtd. de ativos na direita") }}:
                  {{
                    diagramBinaryTree[3].lenghtBinaryRight
                      ? diagramBinaryTree[3].lenghtBinaryRight
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Pontos perna esquerda") }}:
                  {{
                    diagramBinaryTree[3].pointsChildLeft
                      ? diagramBinaryTree[3].pointsChildLeft
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Pontos perna direita") }}:
                  {{
                    diagramBinaryTree[3].pointsChildRight
                      ? diagramBinaryTree[3].pointsChildRight
                      : 0
                  }}
                </div>
              </div>
            </b-popover>
            <b-avatar
              size="70px"
              :variant="
                diagramBinaryTree[3]
                  ? diagramBinaryTree[3].idUser.status == 'active'
                    ? 'light-success'
                    : 'light-info'
                  : 'light-danger'
              "
              text="PA"
              id="popover-target-3"
            />
            <div class="mt-1 limitString">
              <div>
                <strong>{{
                  diagramBinaryTree[3]
                    ? diagramBinaryTree[3].idUser.username
                    : $t("livre")
                }}</strong>
              </div>
            </div>
          </div>
          <b-row class="mt-2 d-none d-lg-flex">
            <b-col cols="6">
              <div
                @click="
                  () =>
                    selectUser(
                      diagramBinaryTree[7] &&
                        diagramBinaryTree[7].idUser.username
                    )
                "
                class="cursor-pointer"
              >
                <b-popover
                  target="popover-target-7"
                  triggers="hover"
                  :delay="delay"
                  placement="top"
                  v-if="diagramBinaryTree[7]"
                >
                  <template #title>
                    {{
                      diagramBinaryTree[7] &&
                      diagramBinaryTree[7].idUser.fullname
                    }}
                  </template>
                  <div>
                    <div>
                      {{ $t("Status") }}:
                      {{
                        diagramBinaryTree[7].pointsUser > 0
                          ? $t("ativo")
                          : $t("inativo")
                      }}
                    </div>
                    <div>
                      {{ $t("Pontuação gerada") }}:
                      {{
                        diagramBinaryTree[7].pointsUser
                          ? diagramBinaryTree[7].pointsUser
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Qtd. de ativos na esquerda") }}:
                      {{
                        diagramBinaryTree[7].lenghtBinaryLeft
                          ? diagramBinaryTree[7].lenghtBinaryLeft
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Qtd. de ativos na direita") }}:
                      {{
                        diagramBinaryTree[7].lenghtBinaryRight
                          ? diagramBinaryTree[7].lenghtBinaryRight
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Pontos perna esquerda") }}:
                      {{
                        diagramBinaryTree[7].pointsChildLeft
                          ? diagramBinaryTree[7].pointsChildLeft
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Pontos perna direita") }}:
                      {{
                        diagramBinaryTree[7].pointsChildRight
                          ? diagramBinaryTree[7].pointsChildRight
                          : 0
                      }}
                    </div>
                  </div>
                </b-popover>
                <b-avatar
                  size="70px"
                  :variant="
                    diagramBinaryTree[7]
                      ? diagramBinaryTree[7].idUser.status == 'active'
                        ? 'light-success'
                        : 'light-info'
                      : 'light-danger'
                  "
                  text="PA"
                  id="popover-target-7"
                />
                <div class="mt-1 limitString">
                  <div>
                    <strong>{{
                      diagramBinaryTree[7]
                        ? diagramBinaryTree[7].idUser.username
                        : $t("livre")
                    }}</strong>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col cols="6">
              <div
                @click="
                  () =>
                    selectUser(
                      diagramBinaryTree[8] &&
                        diagramBinaryTree[8].idUser.username
                    )
                "
                class="cursor-pointer"
              >
                <b-popover
                  target="popover-target-8"
                  triggers="hover"
                  :delay="delay"
                  placement="top"
                  v-if="diagramBinaryTree[8]"
                >
                  <template #title>
                    {{
                      diagramBinaryTree[8] &&
                      diagramBinaryTree[8].idUser.fullname
                    }}
                  </template>
                  <div>
                    <div>
                      {{ $t("Status") }}:
                      {{
                        diagramBinaryTree[8].pointsUser > 0
                          ? $t("ativo")
                          : $t("inativo")
                      }}
                    </div>
                    <div>
                      {{ $t("Pontuação gerada") }}:
                      {{
                        diagramBinaryTree[8].pointsUser
                          ? diagramBinaryTree[8].pointsUser
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Qtd. de ativos na esquerda") }}:
                      {{
                        diagramBinaryTree[8].lenghtBinaryLeft
                          ? diagramBinaryTree[8].lenghtBinaryLeft
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Qtd. de ativos na direita") }}:
                      {{
                        diagramBinaryTree[8].lenghtBinaryRight
                          ? diagramBinaryTree[8].lenghtBinaryRight
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Pontos perna esquerda") }}:
                      {{
                        diagramBinaryTree[8].pointsChildLeft
                          ? diagramBinaryTree[8].pointsChildLeft
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Pontos perna direita") }}:
                      {{
                        diagramBinaryTree[8].pointsChildRight
                          ? diagramBinaryTree[8].pointsChildRight
                          : 0
                      }}
                    </div>
                  </div>
                </b-popover>
                <b-avatar
                  size="70px"
                  :variant="
                    diagramBinaryTree[8]
                      ? diagramBinaryTree[8].idUser.status == 'active'
                        ? 'light-success'
                        : 'light-info'
                      : 'light-danger'
                  "
                  text="PA"
                  id="popover-target-8"
                />
                <div class="mt-1 limitString">
                  <div>
                    <strong>{{
                      diagramBinaryTree[8]
                        ? diagramBinaryTree[8].idUser.username
                        : $t("livre")
                    }}</strong>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <div
            @click="
              () =>
                selectUser(
                  diagramBinaryTree[4] && diagramBinaryTree[4].idUser.username
                )
            "
            class="cursor-pointer"
          >
            <b-popover
              target="popover-target-4"
              triggers="hover"
              :delay="delay"
              placement="top"
              v-if="diagramBinaryTree[4]"
            >
              <template #title>
                {{
                  diagramBinaryTree[4] && diagramBinaryTree[4].idUser.fullname
                }}
              </template>
              <div>
                <div>
                  {{ $t("Status") }}:
                  {{
                    diagramBinaryTree[4].pointsUser > 0
                      ? $t("ativo")
                      : $t("inativo")
                  }}
                </div>
                <div>
                  {{ $t("Pontuação gerada") }}:
                  {{
                    diagramBinaryTree[4].pointsUser
                      ? diagramBinaryTree[4].pointsUser
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Qtd. de ativos na esquerda") }}:
                  {{
                    diagramBinaryTree[4].lenghtBinaryLeft
                      ? diagramBinaryTree[4].lenghtBinaryLeft
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Qtd. de ativos na direita") }}:
                  {{
                    diagramBinaryTree[4].lenghtBinaryRight
                      ? diagramBinaryTree[4].lenghtBinaryRight
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Pontos perna esquerda") }}:
                  {{
                    diagramBinaryTree[4].pointsChildLeft
                      ? diagramBinaryTree[4].pointsChildLeft
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Pontos perna direita") }}:
                  {{
                    diagramBinaryTree[4].pointsChildRight
                      ? diagramBinaryTree[4].pointsChildRight
                      : 0
                  }}
                </div>
              </div>
            </b-popover>
            <b-avatar
              size="70px"
              :variant="
                diagramBinaryTree[4]
                  ? diagramBinaryTree[4].idUser.status == 'active'
                    ? 'light-success'
                    : 'light-info'
                  : 'light-danger'
              "
              text="PA"
              id="popover-target-4"
            />
            <div class="mt-1 limitString">
              <div>
                <strong>{{
                  diagramBinaryTree[4]
                    ? diagramBinaryTree[4].idUser.username
                    : $t("livre")
                }}</strong>
              </div>
            </div>
          </div>
          <b-row class="mt-2 d-none d-lg-flex">
            <b-col cols="6">
              <div
                @click="
                  () =>
                    selectUser(
                      diagramBinaryTree[9] &&
                        diagramBinaryTree[9].idUser.username
                    )
                "
                class="cursor-pointer"
              >
                <b-popover
                  target="popover-target-9"
                  triggers="hover"
                  :delay="delay"
                  placement="top"
                  v-if="diagramBinaryTree[9]"
                >
                  <template #title>
                    {{
                      diagramBinaryTree[9] &&
                      diagramBinaryTree[9].idUser.fullname
                    }}
                  </template>
                  <div>
                    <div>
                      {{ $t("Status") }}:
                      {{
                        diagramBinaryTree[9].pointsUser > 0
                          ? $t("ativo")
                          : $t("inativo")
                      }}
                    </div>
                    <div>
                      {{ $t("Pontuação gerada") }}:
                      {{
                        diagramBinaryTree[9].pointsUser
                          ? diagramBinaryTree[9].pointsUser
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Qtd. de ativos na esquerda") }}:
                      {{
                        diagramBinaryTree[9].lenghtBinaryLeft
                          ? diagramBinaryTree[9].lenghtBinaryLeft
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Qtd. de ativos na direita") }}:
                      {{
                        diagramBinaryTree[9].lenghtBinaryRight
                          ? diagramBinaryTree[9].lenghtBinaryRight
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Pontos perna esquerda") }}:
                      {{
                        diagramBinaryTree[9].pointsChildLeft
                          ? diagramBinaryTree[9].pointsChildLeft
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Pontos perna direita") }}:
                      {{
                        diagramBinaryTree[9].pointsChildRight
                          ? diagramBinaryTree[9].pointsChildRight
                          : 0
                      }}
                    </div>
                  </div>
                </b-popover>
                <b-avatar
                  size="70px"
                  :variant="
                    diagramBinaryTree[9]
                      ? diagramBinaryTree[9].idUser.status == 'active'
                        ? 'light-success'
                        : 'light-info'
                      : 'light-danger'
                  "
                  text="PA"
                  id="popover-target-9"
                />
                <div class="mt-1 limitString">
                  <div>
                    <strong>{{
                      diagramBinaryTree[9]
                        ? diagramBinaryTree[9].idUser.username
                        : $t("livre")
                    }}</strong>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col cols="6">
              <div
                @click="
                  () =>
                    selectUser(
                      diagramBinaryTree[10] &&
                        diagramBinaryTree[10].idUser.username
                    )
                "
                class="cursor-pointer"
              >
                <b-popover
                  target="popover-target-10"
                  triggers="hover"
                  :delay="delay"
                  placement="top"
                  v-if="diagramBinaryTree[10]"
                >
                  <template #title>
                    {{
                      diagramBinaryTree[10] &&
                      diagramBinaryTree[10].idUser.fullname
                    }}
                  </template>
                  <div>
                    <div>
                      {{ $t("Status") }}:
                      {{
                        diagramBinaryTree[10].pointsUser > 0
                          ? $t("ativo")
                          : $t("inativo")
                      }}
                    </div>
                    <div>
                      {{ $t("Pontuação gerada") }}:
                      {{
                        diagramBinaryTree[10].pointsUser
                          ? diagramBinaryTree[10].pointsUser
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Qtd. de ativos na esquerda") }}:
                      {{
                        diagramBinaryTree[10].lenghtBinaryLeft
                          ? diagramBinaryTree[10].lenghtBinaryLeft
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Qtd. de ativos na direita") }}:
                      {{
                        diagramBinaryTree[10].lenghtBinaryRight
                          ? diagramBinaryTree[10].lenghtBinaryRight
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Pontos perna esquerda") }}:
                      {{
                        diagramBinaryTree[10].pointsChildLeft
                          ? diagramBinaryTree[10].pointsChildLeft
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Pontos perna direita") }}:
                      {{
                        diagramBinaryTree[10].pointsChildRight
                          ? diagramBinaryTree[10].pointsChildRight
                          : 0
                      }}
                    </div>
                  </div>
                </b-popover>
                <b-avatar
                  size="70px"
                  :variant="
                    diagramBinaryTree[10]
                      ? diagramBinaryTree[10].idUser.status == 'active'
                        ? 'light-success'
                        : 'light-info'
                      : 'light-danger'
                  "
                  text="PA"
                  id="popover-target-10"
                />
                <div class="mt-1 limitString">
                  <div>
                    <strong>{{
                      diagramBinaryTree[10]
                        ? diagramBinaryTree[10].idUser.username
                        : $t("livre")
                    }}</strong>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <div
            @click="
              () =>
                selectUser(
                  diagramBinaryTree[5] && diagramBinaryTree[5].idUser.username
                )
            "
            class="cursor-pointer"
          >
            <b-popover
              target="popover-target-5"
              triggers="hover"
              :delay="delay"
              placement="top"
              v-if="diagramBinaryTree[5]"
            >
              <template #title>
                {{
                  diagramBinaryTree[5] && diagramBinaryTree[5].idUser.fullname
                }}
              </template>
              <div>
                <div>
                  {{ $t("Status") }}:
                  {{
                    diagramBinaryTree[5].pointsUser > 0
                      ? $t("ativo")
                      : $t("inativo")
                  }}
                </div>
                <div>
                  {{ $t("Pontuação gerada") }}:
                  {{
                    diagramBinaryTree[5].pointsUser
                      ? diagramBinaryTree[5].pointsUser
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Qtd. de ativos na esquerda") }}:
                  {{
                    diagramBinaryTree[5].lenghtBinaryLeft
                      ? diagramBinaryTree[5].lenghtBinaryLeft
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Qtd. de ativos na direita") }}:
                  {{
                    diagramBinaryTree[5].lenghtBinaryRight
                      ? diagramBinaryTree[5].lenghtBinaryRight
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Pontos perna esquerda") }}:
                  {{
                    diagramBinaryTree[5].pointsChildLeft
                      ? diagramBinaryTree[5].pointsChildLeft
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Pontos perna direita") }}:
                  {{
                    diagramBinaryTree[5].pointsChildRight
                      ? diagramBinaryTree[5].pointsChildRight
                      : 0
                  }}
                </div>
              </div>
            </b-popover>
            <b-avatar
              size="70px"
              :variant="
                diagramBinaryTree[5]
                  ? diagramBinaryTree[5].idUser.status == 'active'
                    ? 'light-success'
                    : 'light-info'
                  : 'light-danger'
              "
              text="PA"
              id="popover-target-5"
            />
            <div class="mt-1 limitString">
              <div>
                <strong>{{
                  diagramBinaryTree[5]
                    ? diagramBinaryTree[5].idUser.username
                    : $t("livre")
                }}</strong>
              </div>
            </div>
          </div>
          <b-row class="mt-2 d-none d-lg-flex">
            <b-col cols="6">
              <div
                @click="
                  () =>
                    selectUser(
                      diagramBinaryTree[11] &&
                        diagramBinaryTree[11].idUser.username
                    )
                "
                class="cursor-pointer"
              >
                <b-popover
                  target="popover-target-11"
                  triggers="hover"
                  :delay="delay"
                  placement="top"
                  v-if="diagramBinaryTree[11]"
                >
                  <template #title>
                    {{
                      diagramBinaryTree[11] &&
                      diagramBinaryTree[11].idUser.fullname
                    }}
                  </template>
                  <div>
                    <div>
                      {{ $t("Status") }}:
                      {{
                        diagramBinaryTree[11].pointsUser > 0
                          ? $t("ativo")
                          : $t("inativo")
                      }}
                    </div>
                    <div>
                      {{ $t("Pontuação gerada") }}:
                      {{
                        diagramBinaryTree[11].pointsUser
                          ? diagramBinaryTree[11].pointsUser
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Qtd. de ativos na esquerda") }}:
                      {{
                        diagramBinaryTree[11].lenghtBinaryLeft
                          ? diagramBinaryTree[11].lenghtBinaryLeft
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Qtd. de ativos na direita") }}:
                      {{
                        diagramBinaryTree[11].lenghtBinaryRight
                          ? diagramBinaryTree[11].lenghtBinaryRight
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Pontos perna esquerda") }}:
                      {{
                        diagramBinaryTree[11].pointsChildLeft
                          ? diagramBinaryTree[11].pointsChildLeft
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Pontos perna direita") }}:
                      {{
                        diagramBinaryTree[11].pointsChildRight
                          ? diagramBinaryTree[11].pointsChildRight
                          : 0
                      }}
                    </div>
                  </div>
                </b-popover>
                <b-avatar
                  size="70px"
                  :variant="
                    diagramBinaryTree[11]
                      ? diagramBinaryTree[11].idUser.status == 'active'
                        ? 'light-success'
                        : 'light-info'
                      : 'light-danger'
                  "
                  text="PA"
                  id="popover-target-11"
                />
                <div class="mt-1 limitString">
                  <div>
                    <strong>{{
                      diagramBinaryTree[11]
                        ? diagramBinaryTree[11].idUser.username
                        : $t("livre")
                    }}</strong>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col cols="6">
              <div
                @click="
                  () =>
                    selectUser(
                      diagramBinaryTree[12] &&
                        diagramBinaryTree[12].idUser.username
                    )
                "
                class="cursor-pointer"
              >
                <b-popover
                  target="popover-target-12"
                  triggers="hover"
                  :delay="delay"
                  placement="top"
                  v-if="diagramBinaryTree[12]"
                >
                  <template #title>
                    {{
                      diagramBinaryTree[12] &&
                      diagramBinaryTree[12].idUser.fullname
                    }}
                  </template>
                  <div>
                    <div>
                      {{ $t("Status") }}:
                      {{
                        diagramBinaryTree[12].pointsUser > 0
                          ? $t("ativo")
                          : $t("inativo")
                      }}
                    </div>
                    <div>
                      {{ $t("Pontuação gerada") }}:
                      {{
                        diagramBinaryTree[12].pointsUser
                          ? diagramBinaryTree[12].pointsUser
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Qtd. de ativos na esquerda") }}:
                      {{
                        diagramBinaryTree[12].lenghtBinaryLeft
                          ? diagramBinaryTree[12].lenghtBinaryLeft
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Qtd. de ativos na direita") }}:
                      {{
                        diagramBinaryTree[12].lenghtBinaryRight
                          ? diagramBinaryTree[12].lenghtBinaryRight
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Pontos perna esquerda") }}:
                      {{
                        diagramBinaryTree[12].pointsChildLeft
                          ? diagramBinaryTree[12].pointsChildLeft
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Pontos perna direita") }}:
                      {{
                        diagramBinaryTree[12].pointsChildRight
                          ? diagramBinaryTree[12].pointsChildRight
                          : 0
                      }}
                    </div>
                  </div>
                </b-popover>
                <b-avatar
                  size="70px"
                  :variant="
                    diagramBinaryTree[12]
                      ? diagramBinaryTree[12].idUser.status == 'active'
                        ? 'light-success'
                        : 'light-info'
                      : 'light-danger'
                  "
                  text="PA"
                  id="popover-target-12"
                />
                <div class="mt-1 limitString">
                  <div>
                    <strong>{{
                      diagramBinaryTree[12]
                        ? diagramBinaryTree[12].idUser.username
                        : $t("livre")
                    }}</strong>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <div
            @click="
              () =>
                selectUser(
                  diagramBinaryTree[6] && diagramBinaryTree[6].idUser.username
                )
            "
            class="cursor-pointer"
          >
            <b-popover
              target="popover-target-6"
              triggers="hover"
              :delay="delay"
              placement="top"
              v-if="diagramBinaryTree[6]"
            >
              <template #title>
                {{
                  diagramBinaryTree[6] && diagramBinaryTree[6].idUser.fullname
                }}
              </template>
              <div>
                <div>
                  {{ $t("Status") }}:
                  {{
                    diagramBinaryTree[6].pointsUser > 0
                      ? $t("ativo")
                      : $t("inativo")
                  }}
                </div>
                <div>
                  {{ $t("Pontuação gerada") }}:
                  {{
                    diagramBinaryTree[6].pointsUser
                      ? diagramBinaryTree[6].pointsUser
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Qtd. de ativos na esquerda") }}:
                  {{
                    diagramBinaryTree[6].lenghtBinaryLeft
                      ? diagramBinaryTree[6].lenghtBinaryLeft
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Qtd. de ativos na direita") }}:
                  {{
                    diagramBinaryTree[6].lenghtBinaryRight
                      ? diagramBinaryTree[6].lenghtBinaryRight
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Pontos perna esquerda") }}:
                  {{
                    diagramBinaryTree[6].pointsChildLeft
                      ? diagramBinaryTree[6].pointsChildLeft
                      : 0
                  }}
                </div>
                <div>
                  {{ $t("Pontos perna direita") }}:
                  {{
                    diagramBinaryTree[6].pointsChildRight
                      ? diagramBinaryTree[6].pointsChildRight
                      : 0
                  }}
                </div>
              </div>
            </b-popover>
            <b-avatar
              size="70px"
              :variant="
                diagramBinaryTree[6]
                  ? diagramBinaryTree[6].idUser.status == 'active'
                    ? 'light-success'
                    : 'light-info'
                  : 'light-danger'
              "
              text="PA"
              id="popover-target-6"
            />
            <div class="mt-1 limitString">
              <div>
                <strong>{{
                  diagramBinaryTree[6]
                    ? diagramBinaryTree[6].idUser.username
                    : $t("livre")
                }}</strong>
              </div>
            </div>
          </div>
          <b-row class="mt-2 d-none d-lg-flex">
            <b-col cols="6">
              <div
                @click="
                  () =>
                    selectUser(
                      diagramBinaryTree[13] &&
                        diagramBinaryTree[13].idUser.username
                    )
                "
                class="cursor-pointer"
              >
                <b-popover
                  target="popover-target-13"
                  triggers="hover"
                  :delay="delay"
                  placement="top"
                  v-if="diagramBinaryTree[13]"
                >
                  <template #title>
                    {{
                      diagramBinaryTree[13] &&
                      diagramBinaryTree[13].idUser.fullname
                    }}
                  </template>
                  <div>
                    <div>
                      {{ $t("Status") }}:
                      {{
                        diagramBinaryTree[13].pointsUser > 0
                          ? $t("ativo")
                          : $t("inativo")
                      }}
                    </div>
                    <div>
                      {{ $t("Pontuação gerada") }}:
                      {{
                        diagramBinaryTree[13].pointsUser
                          ? diagramBinaryTree[13].pointsUser
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Qtd. de ativos na esquerda") }}:
                      {{
                        diagramBinaryTree[13].lenghtBinaryLeft
                          ? diagramBinaryTree[13].lenghtBinaryLeft
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Qtd. de ativos na direita") }}:
                      {{
                        diagramBinaryTree[13].lenghtBinaryRight
                          ? diagramBinaryTree[13].lenghtBinaryRight
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Pontos perna esquerda") }}:
                      {{
                        diagramBinaryTree[13].pointsChildLeft
                          ? diagramBinaryTree[13].pointsChildLeft
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Pontos perna direita") }}:
                      {{
                        diagramBinaryTree[13].pointsChildRight
                          ? diagramBinaryTree[13].pointsChildRight
                          : 0
                      }}
                    </div>
                  </div>
                </b-popover>
                <b-avatar
                  size="70px"
                  :variant="
                    diagramBinaryTree[13]
                      ? diagramBinaryTree[13].idUser.status == 'active'
                        ? 'light-success'
                        : 'light-info'
                      : 'light-danger'
                  "
                  text="PA"
                  id="popover-target-13"
                />
                <div class="mt-1 limitString">
                  <div>
                    <strong>{{
                      diagramBinaryTree[13]
                        ? diagramBinaryTree[13].idUser.username
                        : $t("livre")
                    }}</strong>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col cols="6">
              <div
                @click="
                  () =>
                    selectUser(
                      diagramBinaryTree[14] &&
                        diagramBinaryTree[14].idUser.username
                    )
                "
                class="cursor-pointer"
              >
                <b-popover
                  target="popover-target-14"
                  triggers="hover"
                  :delay="delay"
                  placement="top"
                  v-if="diagramBinaryTree[14]"
                >
                  <template #title>
                    {{
                      diagramBinaryTree[14] &&
                      diagramBinaryTree[14].idUser.fullname
                    }}
                  </template>
                  <div>
                    <div>
                      {{ $t("Status") }}:
                      {{
                        diagramBinaryTree[14].pointsUser > 0
                          ? $t("ativo")
                          : $t("inativo")
                      }}
                    </div>
                    <div>
                      {{ $t("Pontuação gerada") }}:
                      {{
                        diagramBinaryTree[14].pointsUser
                          ? diagramBinaryTree[14].pointsUser
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Qtd. de ativos na esquerda") }}:
                      {{
                        diagramBinaryTree[14].lenghtBinaryLeft
                          ? diagramBinaryTree[14].lenghtBinaryLeft
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Qtd. de ativos na direita") }}:
                      {{
                        diagramBinaryTree[14].lenghtBinaryRight
                          ? diagramBinaryTree[14].lenghtBinaryRight
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Pontos perna esquerda") }}:
                      {{
                        diagramBinaryTree[14].pointsChildLeft
                          ? diagramBinaryTree[14].pointsChildLeft
                          : 0
                      }}
                    </div>
                    <div>
                      {{ $t("Pontos perna direita") }}:
                      {{
                        diagramBinaryTree[14].pointsChildRight
                          ? diagramBinaryTree[14].pointsChildRight
                          : 0
                      }}
                    </div>
                  </div>
                </b-popover>
                <b-avatar
                  size="70px"
                  :variant="
                    diagramBinaryTree[14]
                      ? diagramBinaryTree[14].idUser.status == 'active'
                        ? 'light-success'
                        : 'light-info'
                      : 'light-danger'
                  "
                  text="PA"
                  id="popover-target-14"
                />
                <div class="mt-1 limitString">
                  <div>
                    <strong>{{
                      diagramBinaryTree[14]
                        ? diagramBinaryTree[14].idUser.username
                        : $t("livre")
                    }}</strong>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BFormRadio,
  VBPopover,
  BPopover,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { mapGetters, mapActions } from "vuex";
import { debounce } from "debounce";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import copy from 'copy-text-to-clipboard';

export default {
  components: {
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormRadio,
    BPopover,
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  directives: {
    "b-popover": VBPopover,
    Ripple,
  },
  data() {
    return {
      selectedLeg: "",
      diagramBinaryTree: {},
      ownerBinary: {},
      searchUsername: "",
      userNotFount: false,
      delay: { show: 500, hide: 500 },
      showAlert: false,
      textAlert: "",
      colorAlert: "warning",
      referenceLink: "",
    };
  },
  computed: {
    ...mapGetters(["returnUser", "returnLocale"]),
  },
  watch: {
    searchUsername: debounce(function (newVal) {
      if (newVal != "") this.renderBinaryTree(newVal);
    }, 250),
  },
  mounted() {
    this.renderBinaryTree(this.returnUser.username);
    this.referenceLink =
      process.env.VUE_APP_FRONT_URL + "/ref/" + this.returnUser._id;
  },
  methods: {
    copyReferenceLink() {
      let copyText = this.referenceLink;
      copy(copyText);
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          icon: "CoffeeIcon",
          variant: "info",
          title: copyText,
          text: this.$i18n.t(
            "esse link foi copiado para a sua área de transferências"
          ),
        },
      });
    },
    top() {
      this.searchUsername = "";
      this.renderBinaryTree(this.returnUser.username);
    },
    up() {
      this.searchUsername = "";
      this.renderBinaryTree(this.diagramBinaryTree[0].binaryFather.username);
    },
    selectUser(username) {
      this.searchUsername = "";
      this.renderBinaryTree(username);
    },
    saveSelectedLeg() {
      this.$store
        .dispatch("saveSelectedLeg", this.selectedLeg)
        .then((resp) => {
          this.showAlert = true;
          this.colorAlert = "success";
          this.textAlert = this.$i18n.t("Lado binário salvo!");
        })
        .catch((err) => {
          this.showAlert = true;
          this.colorAlert = "danger";
          this.textAlert = this.$i18n.t("Erro ao salvar o lado binário!");
        });
    },
    renderBinaryTree(username) {
      this.$store
        .dispatch("diagramBinaryTree", username)
        .then((resp) => {
          if (resp.diagramBinary[0].createdAt >= this.returnUser.createdAt) {
            this.diagramBinaryTree = resp.diagramBinary;
            this.userNotFount = null;
            this.ownerBinary = resp.ownerBinary;
            this.selectedLeg = resp.ownerBinary.selectedLeg;
            if (
              this.diagramBinaryTree[0].idUser.username == this.searchUsername
            )
              this.userNotFount = true;
          }
        })
        .catch((err) => {
          this.userNotFount = false;
        });
    },
  },
};
</script>

<style>
.limitString {
  white-space: nowrap;
  max-width: 22rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>